import chroma from "chroma-js";
import L from "leaflet";
import _ from "lodash";


/**
 * 
 * TODO 
 * Documentation et Code a revoir
 * Remplacer l'utilisation de paramUrl par Object.keys
 * 
 * Variable initialisation
 * @colors 
 * @imgUri : ['img','layer']
 * @bounds
 * @globalAlpha
 * @param
    layer:windd,
    noZero:
    imageSmoothingEnabled
    imageSmoothingQuality
    debug
    scale:0-12
    dynAlpha
    type:arrow, arrow_reverse, raster
    colors: / optionel / par defaut bleu au rouge
    type:raster/arrow,
    ranges:{
          src,[0,255] min/max
          tgt,[0,255] min/max
        }
    isError:false/true
    
    exemple 
    let layer = L.canvasGridLayer(param);
    layer.addTo(map)
    
 * 
 */

 /**
  * 
  * 
  * CanvasGridLayer
  * Permet d'afficher un layer suivant les parametre saisie
  * 
  *
  * 
      imgUri:{
        key:string (uri) //windd, windf, gust
      } 
    param:{
      colors:array,
      colorsDomain:array [min,max]
      type:raster/arrow,
      noZero:true/false
      dynAlpha:true/false
      
      paramUrl:['windd']
      range:{
        windd:{
          srcmin,
          srcmax
        }
      }
    }
    bounds:{
      max:int
      min:int
    }
    debug:true/false
    imageSmoothingEnabled:true/false
    imageSmoothingQuality:low || medium || high
    

    exemple : L.canvasGridLayer().addTo(map);

  * 
  */

L.GridLayer.CanvasGridLayer = L.GridLayer.extend({
  initialize: function(options) {
    L.Util.setOptions(options);
    this._opts = options;
    this._gradient = chroma
      .scale(
        this._opts.param.colors || [
          "lightcyan",
          "cyan",
          "dodgerblue",
          "orange",
          "indianred"
        ]
      )
      .classes(
        this._opts.param.classes || [0, 10, 11,  12, 13, 14, 15, 255]
        )
    this.src = this.src || [];
    this._initImages();
  },

  _initImages: function() {
    var self = this;
    _.each(this._opts.imgUri, function(img, key) {
      self.src[key] = {};
      self._initImage(key, img);
    });
  },

  _initImage: function(key, img) {
    if (!this.src[key]) return;
    var self = this;
    this._opts.param.isError = false;
    this.src[key]._image = null;
    this.src[key]._image = new Image();
    this.src[key]._image.crossOrigin = "Anonymous";
    this.src[key]._image.onload = function() {
      self._buildImage(key);
      self._redrawImage(key);
      self.redraw();
      self._opts.onReady();
    };
    this.src[key]._image.onerror = function() {
      self._opts.param.isError = true;
    };
    this.src[key]._image.src = img;
  },

  _buildImage: function(key) {
    if (!this.src[key]._image) return;
    this.src[key]._canvas =
      this.src[key]._canvas || document.createElement("canvas");
    this.src[key]._canvas.id = this.src[key]._canvas.id || key;
    this.src[key]._context =
      this.src[key]._context || this.src[key]._canvas.getContext("2d");
      var scale = this._opts.scale || 2;
      this.src[key]._image.width  = this.src[key]._image.width * scale
      this.src[key]._image.height  = this.src[key]._image.height * scale;
    this.src[key]._canvas.width = this.src[key]._image.width;
    this.src[key]._canvas.height = this.src[key]._image.height;
    var el = document.getElementById(key);
    if (el) el.remove();
    if (this._opts.debug) document.body.append(this.src[key]._canvas);
  },

  _redrawImage: function(key) {
    if (!this.src[key]._context || this.src[key]._image.width == 0) return;
    this.src[key]._context.clearRect(
      0,
      0,
      this.src[key]._canvas.width,
      this.src[key]._canvas.height
    );
    this.src[key]._context.drawImage(
      this.src[key]._image,
      0,
      0,
      this.src[key]._canvas.width,
      this.src[key]._canvas.height
    );
    this.src[key]._rawImage = this.src[key]._context.getImageData(
      0,
      0,
      this.src[key]._canvas.width,
      this.src[key]._canvas.height
    );
    this.src[key]._colorImage = this.src[key]._context.getImageData(
      0,
      0,
      this.src[key]._canvas.width,
      this.src[key]._canvas.height
    );
    switch (this._opts.param.type) {
      case "raster":
        for (var i = 0; i < this.src[key]._colorImage.data.length / 4; i++) {
          // get gray color
          var val = this.src[key]._colorImage.data[i * 4];
          // convert to real value
          var rval = this.grayToValue(val, key);
          var coef = this.grayToCoef(val, key);
          // get colorized gray value
          var grad = this._gradient(val).rgb();
          this.src[key]._colorImage.data[i * 4] = grad[0];
          this.src[key]._colorImage.data[i * 4 + 1] = grad[1];
          this.src[key]._colorImage.data[i * 4 + 2] = grad[2];
          if (this._opts.param.noZero && rval == 0)
            this.src[key]._colorImage.data[i * 4 + 3] = 0;
          else if (this._opts.param.dynAlpha)
            this.src[key]._colorImage.data[i * 4 + 3] = 255 * coef;
          else this.src[key]._colorImage.data[i * 4 + 3] = 255;
        }
        this.src[key]._context.putImageData(
          this.src[key]._colorImage,
          0,
          0,
          0,
          0,
          this.src[key]._canvas.width,
          this.src[key]._canvas.height
        );
        break;
    }
  },

  createTile: function(coords) {
    var self = this;
    var tile = L.DomUtil.create("canvas");
    var ctx = tile.getContext("2d");

    tile.size = this.getTileSize();
    tile.width = tile.size.x;
    tile.height = tile.size.y;
    tile.bounds = this._getBoundTile(tile, coords);
    tile.clipping = this._getClipping(tile);

    ctx.imageSmoothingEnabled =
      typeof this._opts.imageSmoothingEnabled !== undefined
        ? this._opts.imageSmoothingEnabled
        : true;
    ctx.imageSmoothingQuality = this._opts.imageSmoothingQuality || "high";

    switch (this._opts.param.type) {
      case "arrow":
        var cellX = 6;
        var cellY = 6;

        var degWidth = tile.bounds.max.x - tile.bounds.min.x;
        var degHeight = tile.bounds.max.y - tile.bounds.min.y;

        var degStepWidth = degWidth / cellX;
        var degStepHeight = degHeight / cellY;

        // var pixWidth = degWidth / tile.width;
        // var pixHeight = degHeight / tile.height;

        var pixStepWidth = tile.width / cellX;
        var pixStepHeight = tile.height / cellY;

        for (var i = 0; i < cellX * cellY; i++) {
          var x = i % cellX;
          var y = Math.floor(i / cellY);
          var coordsTile = {
            lat: tile.bounds.min.y + degStepHeight * y + degStepHeight / 2,
            lng: tile.bounds.min.x + degStepWidth * x + degStepWidth / 2
          };
          var v = this.getValueAt(coordsTile, i);
          var length = v ? 1.2 * v.windf + 4 : 0;
          var posX = x * pixStepWidth;
          var posY = y * pixStepHeight;

          if (this._opts.debug) {
            ctx.fillStyle = "red";
            ctx.font = "bold 8px";
            ctx.fillText(i, posX + 4, posY + 10);
            ctx.fillText(
              Math.round(v && v.windd ? v.windd : ""),
              posX + 4,
              posY + 20
            );
            ctx.fillText(
              Math.round(v && v.windf ? v.windf : ""),
              posX + 4,
              posY + 30
            );
            ctx.strokeStyle = "red";
            ctx.strokeRect(posX, posY, pixStepWidth, pixStepHeight);
          }
          if (v) {
            ctx.save();
            // style
            ctx.shadowColor = "rgba(0,0,0,0.2)";
            ctx.shadowBlur = 2;
            ctx.fillStyle = "white";
            ctx.strokeStyle = ctx.fillStyle;
            // transform
            ctx.translate(posX + pixStepWidth / 2, posY + pixStepHeight / 2);
            ctx.rotate((v.windd * Math.PI) / 180);
            // line
            ctx.beginPath();
            ctx.moveTo(0, -(length / 2));
            ctx.lineTo(0, length / 2);
            ctx.stroke();
            // arrow
            ctx.moveTo(0, -(length / 2)); //simple point
            ctx.lineTo(-4, -(length / 2));
            ctx.lineTo(0, -(length / 2) - 4);
            ctx.lineTo(4, -(length / 2));
            ctx.lineTo(0, -(length / 2));
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }
        }
        break;
      case "raster":
      default:
        if (
          !this.src[this._opts.param.paramUrl[0]]._context ||
          this.src[this._opts.param.paramUrl[0]]._canvas.width == 0
        )
          return tile;
        ctx.globalAlpha = this._opts.param.globalAlpha || 0.6;
        ctx.drawImage(
          this.src[this._opts.param.paramUrl[0]]._canvas,
          tile.clipping.x,
          tile.clipping.y,
          tile.clipping.w,
          tile.clipping.h,
          0,
          0,
          tile.size.x,
          tile.size.y
        );
        ctx.globalAlpha = 1;
        break;
    }

    if (this._opts.debug) {
      // debug tile
      ctx.strokeStyle = "lime";
      ctx.strokeRect(0, 0, tile.size.x, tile.size.y);
      ctx.fillStyle = "lime";
      ctx.fillText(
        coords.x +
          "; " +
          coords.y +
          " | " +
          tile.bounds.min.y.toFixed(4) +
          "; " +
          tile.bounds.min.x.toFixed(4),
        10,
        15
      );
      // debug src
      _.each(this._opts.param.paramUrl, function(paramUrl) {
        if (!self.src[paramUrl]._context) return;
        self.src[paramUrl]._context.strokeStyle = "red";
        self.src[paramUrl]._context.strokeRect(
          tile.clipping.x,
          tile.clipping.y,
          tile.clipping.w,
          tile.clipping.h
        );
        self.src[paramUrl]._context.fillStyle = "red";
        self.src[paramUrl]._context.fillText(
          tile.bounds.max.y.toFixed(4) + "; " + tile.bounds.max.x.toFixed(4),
          Math.max(10, tile.clipping.x + 10),
          Math.max(10, tile.clipping.y + 10)
        );
      });
    }
    return tile;
  },

  onRemove: function() {
    var self = this;
    _.each(this._opts.param.paramUrl, function(paramUrl) {
      var el = document.getElementById(
        self.src[paramUrl]._canvas ? self.src[paramUrl]._canvas.id : null
      );
      if (el) el.remove();
    });
    this._removeAllTiles();
    L.DomUtil.remove(this._container);
    this._container = null;
    this._tileZoom = undefined;
    this._opts.param.isError = false;
  },

  _getClipping: function(tile) {
    var src = this.src[this._opts.param.paramUrl[0]];
    var opts = this._opts;
    opts.bounds.width = opts.bounds.max.x - opts.bounds.min.x;
    opts.bounds.height = opts.bounds.max.y - opts.bounds.min.y;
    var xMin =
        src._image.width *
        ((tile.bounds.min.x - opts.bounds.min.x) / opts.bounds.width),
      yMin =
        src._image.height *
        ((opts.bounds.max.y - tile.bounds.max.y) / opts.bounds.height),
      xMax =
        src._image.width *
        ((tile.bounds.max.x - opts.bounds.min.x) / opts.bounds.width),
      yMax =
        src._image.height *
        ((opts.bounds.max.y - tile.bounds.min.y) / opts.bounds.height),
      w = xMin - xMax,
      h = yMin - yMax;
    if (opts.debug && this._context) {
      _.each(opts.param.paramUrl, function(paramUrl) {
        this.src[paramUrl]._context.strokeStyle = "red";
        this.src[paramUrl]._context.strokeRect(xMin, yMin, w, h);
      });
    }
    return {
      x: xMax,
      y: yMax,
      w: w,
      h: h
    };
  },

  _getBoundTile: function(tile, coords) {
    var coordsPoint = coords.scaleBy(tile.size);
    var north_west = this._map.unproject(coordsPoint, coords.z);

    // var north_west_point = this._map.latLngToContainerPoint([north_west.lat, north_west.lng]);
    var north_west_point = this._map.latLngToLayerPoint([
      north_west.lat,
      north_west.lng
    ]);

    // var south_east = this._map.containerPointToLatLng([1 * north_west_point.x + tile.size.x, 1 * north_west_point.y + tile.size.y]);
    var south_east = this._map.layerPointToLatLng([
      1 * north_west_point.x + tile.size.x,
      1 * north_west_point.y + tile.size.y
    ]);

    // var south_east_point = this._map.latLngToContainerPoint([south_east.lat, south_east.lng]);
    /*
    var south_east_point = this._map.latLngToLayerPoint([
      south_east.lat,
      south_east.lng
    ]);
    */

    return {
      max: { x: south_east.lng, y: south_east.lat },
      min: { x: north_west.lng, y: north_west.lat }
    };
  },

  setOptions: function(options) {
    var self = this;
    _.each(options, function(value, key) {
      if (_.keys(self._opts).indexOf(key) >= 0) {
        self._opts[key] = value;
      }
    });
    // this.initialize(this._opts);
    this._initImages();
  },

  _isInBbox: function(lat, lng) {
    // var bounds = this._opts.bounds;
    var isIn =
      lat <= this._opts.bounds.max.y &&
      lat >= this._opts.bounds.min.y &&
      lng <= this._opts.bounds.max.x &&
      lng >= this._opts.bounds.min.x;
    return isIn;
  },

  grayToCoef: function(gray, paramUrl) {
    // var min = this._opts.param.range[paramUrl].srcmin;
    // var max = this._opts.param.range[paramUrl].srcmax;
    var coef = gray / (this._opts.param.range[paramUrl].dstmax || 255);
    return coef;
  },

  grayToValue: function(gray, paramUrl) {
    var min = this._opts.param.range[paramUrl].srcmin;
    var max = this._opts.param.range[paramUrl].srcmax;
    var coef = gray / (this._opts.param.range[paramUrl].dstmax || 255);
    var value = coef * (max - min) + min;
    return value;
  },

  getValueAt: function(coords, debug = false) {
    var self = this;
    if (!this._isInBbox(coords.lat, coords.lng)) return;

    var imgWidth = this.src[this._opts.param.paramUrl[0]]._image.width;
    var imgHeight = this.src[this._opts.param.paramUrl[0]]._image.height;
    var x =
      (coords.lng - this._opts.bounds.min.x) /
      ((this._opts.bounds.max.x - this._opts.bounds.min.x) / imgWidth);
    var y =
      (this._opts.bounds.max.y - coords.lat) /
      ((this._opts.bounds.max.y - this._opts.bounds.min.y) / imgHeight);

    /* bilinear interp */
    var lx = x - Math.floor(x);
    var ly = y - Math.floor(y);
    var rx = 1 - lx;
    var ry = 1 - ly;

    var values = {};

    _.each(this._opts.param.paramUrl, function(paramUrl) {
      if (!self.src[paramUrl]._rawImage) return;
      var srcData = self.src[paramUrl]._rawImage;
      var g00 = srcData.data[Math.floor(x) * 4 + imgWidth * Math.floor(y) * 4];
      var g10 = srcData.data[Math.ceil(x) * 4 + imgWidth * Math.floor(y) * 4];
      var g01 = srcData.data[Math.floor(x) * 4 + imgWidth * Math.ceil(y) * 4];
      var g11 = srcData.data[Math.ceil(x) * 4 + imgWidth * Math.ceil(y) * 4];

      var value = null;
      if (Math.max(g00, g10, g01, g11) == 255) {
        value = srcData.data[Math.round(x) * 4 + imgWidth * Math.round(y) * 4];
      } else {
        value = g00 * rx * ry + g10 * lx * ry + g01 * rx * ly + g11 * lx * ly;
      }

      var rval = self.grayToValue(value, paramUrl);

      // if raster is arrow type, reverse wind dir
      if (self._opts.param.type == "arrow" && paramUrl == "windd") {
        rval = rval >= 180 ? 1 * rval - 180 : 1 * rval + 180;
      }

      values[paramUrl] = rval;

      if (self._opts.debug) {
        self.src[paramUrl]._context.fillStyle = "lime";
        self.src[paramUrl]._context.fillRect(x, y, 3, 3);
        self.src[paramUrl]._context.fillText(debug || "", x + 10, y + 8);
      }
    });

    return values;
  }
});

L.gridLayer.canvasGridLayer = function(opts) {
  return new L.GridLayer.CanvasGridLayer(opts);
};
