import axios from 'axios';

import config from "./config";

axios.defaults.baseURL = config.API_URI;

// extract data from request result, empty string is converted null
const getData = res => res.data || null;

const api = {
	/**
	 * Set token expired callback.
	 *
	 * When axios will detect an error it will call this callback.
	 * @param {Function} cb
	 * @return {void}
	 */
	onTokenExpired: cb => {
		axios.interceptors.response.use(me => me, err => {
			console.log("ko", err);
			if (err.response.status === 401 || err.response.status == 403) {
				api.setBearerToken(null);
				cb();
			}
			return Promise.reject(err);
		});
	},

	/**
	* Add api error callback.
	*
	* @param {(err:any) => void} cb
	* @return {void}
	*/
	onError: cb => {
		axios.interceptors.response.use(me => me, err => {
			cb(err);
			return Promise.reject(err);
		});
	},

	/**
	* Set/Unset Bearer token for auth headers.
	* @param {string | null} token The new auth token or null.
	*/
	setBearerToken: token => {
		api.tokenString = token;
		if (token) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		} else {
			axios.defaults.headers.common["Authorization"] = undefined;
		}
	},

	token: {
		validate: id => axios.get(`/token/${id}`).then(getData),
		get: ({ login, password }) => axios.post("/token", { login, password }).then(getData)
	},

	users: {
		get: id => axios.get(`/users/${id}`).then(getData)
	},

	clients: {
		getAll: () => axios.get(`/clients`).then(getData),
		get: id => axios.get(`/clients/${id}`).then(getData)
	},
	address: {
		get: () => axios.get(`https://api-adresse.data.gouv.fr/search/?q=8+bd+du+port`).then(getData),
	},
	forecast: {
		getBbox:(day, day_fcast)=>
			axios.get(`hailcast/layers/${day}/bbox?day_fcast=${day_fcast}&token=${api.tokenString}`).then(getData),
		getDay: (day, day_fcast) => 
		axios.defaults.baseURL +
		`hailcast/layers/${day}?day_fcast=${day_fcast}&token=${api.tokenString}`,
	},
	radar: {
		getDay: (day) => 
		axios.defaults.baseURL +
		`radar/layers/days/${day}?token=${api.tokenString}`,
		getHours: (timestamp) => axios.defaults.baseURL +
		`radar/layers/hours/${timestamp}?token=${api.tokenString}`,
		getQuarter: (timestamp) => axios.defaults.baseURL +
		`radar/layers/quarters/${timestamp}?token=${api.tokenString}`,
		getDayValid: (day) => axios.get(`radar/layers/days/${day}?token=${api.tokenString}`).then(getData),
		getHourValid: (timestamp) => axios.get(`radar/layers/hours/${timestamp}?token=${api.tokenString}`).then(getData),
		getHoursDatas: (timestamp, codeISO) => axios.get(`/radar/data/hours/${timestamp}/${codeISO}`).then(getData),
		getDayDatas: (day, codeISO) => axios.get(`/radar/data/days/${day}/${codeISO}`).then(getData),

		
	}

};

export default api;
