import Vue from 'vue';

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '@/store';

/* Components */
import NotFound from '@/pages/NotFound';
import Login from '@/pages/Login';
// import Map from '@/pages/Map';
import MapV2 from '@/pages/MapV2';
import TestMap from '@/pages/TestMap';
import BrowserError from '@/pages/BrowserError';

/** TODO sortie l'init du store de cette logique, et utiliser beforeEach pour check login */


/** store init before routing. */
const initStore = f => (to, from, next) => {
	const cb = () => f(to, from, next);
	if (!store.state.ready) {
		store.dispatch('init').then(cb);
	} else {
		cb();
	}
};

const ifNotLogged = initStore((to, from, next) => {
	if (!store.getters['user/isLogged']) {
		return next();
	}
	return next('/');
});

const ifLogged = initStore((to, from, next) => {
	if (store.getters['user/isLogged']) {
		return next();
	}
	return next('/login');
});

/* Routes */
const router = new VueRouter({
	mode: 'history',
	routes: [
		{ 
			path: '*', 
			component: NotFound
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: ifNotLogged // ifLogged
		},
		{
			path: '/',
			name: 'map',
			component: MapV2,
			beforeEnter: ifLogged
		},
		// {
		// 	path: '/mapv2',
		// 	name: 'mapv2',
		// 	component: MapV2,
		// 	beforeEnter: ifLogged
		// },
		{
			path: '/testmap',
			name: 'testmap',
			component: TestMap,
			beforeEnter: ifLogged
		},
		{
			path: '/browser-error',
			name: 'browser-error',
			component: BrowserError
		},
	]
});

export default router;
