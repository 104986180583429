<template>
  <v-col class="pa-0 ma-0" cols="12">
    <div class="loader" v-if="isLoading">
      <img style="height: 50px" src="/assets/spinner.gif" /><br />
      Chargement en cours
    </div>
    <div class="map-container col-12">
      <div
        id="map"
        ref="map"
        class="map"
        style="width: 100%; height: 97vh"
      ></div>
      <v-col class="status-bar" style="pointer-events: none">
        <v-icon :color="stateObs ? 'green' : 'red'">mdi mdi-circle</v-icon>
        Calque d'observation<br />
        <v-icon :color="stateFcast ? 'green' : 'red'">mdi mdi-circle</v-icon>
        Calque de prévision
      </v-col>
      <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
      <v-col class="timelines pa-6" style="pointer-events: none">
        <v-col md="4" sm="12" class="pa-3" style="pointer-events: initial">
          <v-alert color="orange" dense v-if="isHistory"
            >Mode historique</v-alert
          >
          <v-alert color="green" dense v-if="isForecast && !isHistory"
            >Mode Prévision</v-alert
          >
          <v-sheet color="black">
            <v-btn @click="optionDeploy = !optionDeploy" small
              ><v-icon color="white">mdi mdi-minus </v-icon></v-btn
            >

            <v-col class="content-panel" v-if="optionDeploy">
              <div class="option-panel" v-if="layers">
                <label>Opacité Observation</label><br />
                <v-slider
                  step="0.1"
                  type="range"
                  min="0"
                  max="1"
                  v-model="layerOpacity"
                /> 
              </div>
              <div class="option-panel" v-if="layersFcast">
                <label>Opacité Prévision</label><br />
                <v-slider
                  step="0.1"
                  type="range"
                  min="0"
                  max="1"
                  v-model="layerFcastOpacity"
                />
              </div>
              <div
                class="option-panel"
                v-for="option in options"
                :key="option.name"
              >
                <label>
                  <input
                    :type="option.type"
                    v-if="option.isObs"
                    @input="isObs ? (isObs = false) : (isObs = true)"
                    :checked="isObs"
                  />
                  <input
                    :type="option.type"
                    v-else-if="option.pos == 'before' && !option.isObs"
                  />
                  {{ option.name }}
                  <input
                    :type="option.type"
                    v-if="option.pos == 'after' && !option.isObs"
                  />
                </label>
              </div>
              <div class="option-panel">
                <label>Date</label><br />
                <v-text-field
                  type="date"
                  v-model="inputDate"
                  @input="
                    resetFocus();
                    isToday = false;
                    selectedDay = true;
                    setLayer(inputDate, 'day');
                  "
                />
              </div>
              <div class="option-panel" v-if="!isToday">
                <button
                  @click="
                    isToday = true;
                    inputDate = todayDate;
                    setLayer(todayDate, 'day');
                    resetFocus();
                    timelineObs[3].focus = true;
                  "
                  class="btn btn-sm btn-block btn-success"
                >
                  Revenir à aujourd'hui
                </button>
              </div>
              <div class="option-panel" v-if="currentSelect == 3 && !isHistory">
                <label>Quart de journée d'observation</label><br />
                <select
                  @input="
                    selectedDay = false;
                    setLayer($event.target.value);
                  "
                  class="form-control"
                >
                  <option selected disabled>Sélectionnez une heure</option>
                  <option
                    v-for="hour in hours"
                    :key="hour.label"
                    :value="hour.value"
                    :disabled="hour.disable"
                  >
                    {{ hour.label }} h
                  </option>
                </select>
              </div>
              <div class="option-panel">
                <v-checkbox v-model="viewLegend" :label="`Voir la légende`" />

                <ul class="legend" v-if="viewLegend">
                  <li>
                    <span class="classes1"></span>56 à 59 dbz - Grêle modérée
                  </li>
                  <li>
                    <span class="classes2"></span> 60 à 64 dbz - Grêle
                    importante
                  </li>
                  <li>
                    <span class="classes3"></span>64 + dbz - Grêle majeure
                  </li>
                </ul>
              </div>
            </v-col>
          </v-sheet>
        </v-col>
        <v-col class="timeline obs" style="pointer-events: initial">
          <v-slider
            v-model="currentSelect"
            :tick-labels="!isHistory ? ticklabels : ticklabelsHistory"
            :min="0"
            :max="4"
            ticks="always"
          ></v-slider>
        </v-col>
      </v-col>
    </div>
  </v-col>
</template>

<script>
/**
 * Map.vue
 *
 * Carte d'affichage des risques de greles sur les communes de france.
 *
 */

/* eslint-disable */
import moment from "moment-timezone";
import Map from "ol/Map";
import "ol/ol.css";
import "ol-geocoder/dist/ol-geocoder.min.css";
import Control from "ol/control/Control";
import Geocoder from "ol-geocoder";

import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import LayerGroup from "ol/layer/Group";
import { fromLonLat } from "ol/proj";

import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import MVT from "ol/format/MVT";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import XYZ from "ol/source/XYZ";

import { transformExtent } from "ol/proj";

import View from "ol/View";
import { Stroke, Style, Fill } from "ol/style";

import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as getProjection } from "ol/proj";

import ImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";

import Overlay from "ol/Overlay";
import axios from "axios";

import "mapbox-gl-leaflet";
import "@/utils/canvasgridlayer.js";
import L from "leaflet";
import * as kdTree from "kd-tree-javascript";
import * as turf from "@turf/turf";
import api from "@/api";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
    isHistory() {
      if (moment(this.inputDate).diff(moment(), "days") != 0) {
        return true;
      } else {
        return false;
      }
    },
    isForecast() {
      if (
        moment(this.timelineObs[this.currentSelect].timestamp).diff(
          moment(),
          "hours"
        ) >= 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    ticklabelsHistory() {
      let ticks = [];
      this.timelineObs.forEach((el) => {
        ticks.push(
          moment(this.inputDate).add(el.interval, "day").format("DD/MM/YY")
        );
      });
      return ticks;
    },
  },
  data() {
    return {
      map: null,
      isObs: true,
      isLoading: false,
      currentSelect: 3,
      isToday: true,
      todayDate: moment().startOf("day").format("YYYY-MM-DD"),
      inputDate: moment().format("YYYY-MM-DD"),
      isCumul: false,
      scaleSelected: 1,
      layerOpacity: 1,
      layerFcastOpacity: 1,
      optionDeploy: true,
      options: [],
      hours: [],
      timeBarUseObs: "50%",
      timeBarUseCumu: "32%",
      viewLegend: false,
      timelineObs: [
        {
          label: "J-3",
          interval: -3,
          timestamp: new moment(this.inputDate)
            .subtract(3, "days")
            .format("YYYY-MM-DD"),
          focus: false,
          usebar: "94%",
        },
        {
          label: "J-2",
          interval: -2,
          timestamp: new moment(this.inputDate)
            .subtract(2, "days")
            .format("YYYY-MM-DD"),
          focus: false,
          usebar: "79%",
        },
        {
          label: "J-1",
          interval: -1,
          timestamp: new moment(this.inputDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          focus: false,
          usebar: "65%",
        },
        {
          label: "J+0",
          interval: 0,
          timestamp: new moment(this.inputDate)
            .startOf("day")
            .format("YYYY-MM-DD"),
          focus: true,
          usebar: "50%",
        },
        {
          label: "J+1",
          interval: 1,
          timestamp: new moment(this.inputDate)
            .add(1, "days")
            .format("YYYY-MM-DD"),
          focus: false,
          usebar: "36%",
          disabled: true,
        },
        // {
        //   label: "J+2",
        //   interval: 2,
        //   timestamp: new moment(this.inputDate)
        //     .add(2, "days")
        //     .format("YYYY-MM-DD"),
        //   focus: false,
        //   usebar: "22%",
        //   disabled: true,
        // },
      ],
      survolStatus: false,
      currentImage: 0,
      popup: null,
      nearest: null,
      modeSwitch: null,
      layers: null,
      layersFcast: null,
      stateObs: false,
      stateFcast: false,
      selectedTimestamp: null,
      markersList: [],
      proj3106001: null,
      selectedDay: false,
      ticklabels: [],
    };
  },
  methods: {
    initLabels() {
      this.timelineObs.forEach((el) => {
        this.ticklabels.push(el.label);
      });
    },

    initMap() {
      /* base map */
      proj4.defs(
        "EPSG:3106001",
        "+proj=stere +lat_0=90 +lat_ts=45 +lon_0=0 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"
      );
      register(proj4);

      this.proj3106001 = getProjection("EPSG:3106001");

      var tileLayer = new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/pmcom/ck6m9eny40xsn1imy756u9tyw/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA",
        }),
      });
      tileLayer.setExtent(null); // prevent double occurrence of map

      /* layer départements */
      const dptStyle = new Style({
        stroke: new Stroke({
          color: "rgba(66, 135, 245, 0.8)",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(20,20,20,0)",
        }),
      });

      var dptLayer = new VectorLayer({
        minZoom: 4,
        maxZoom: 9,
        source: new VectorSource({
          format: new GeoJSON(),
          url: "/layer/departement.geojson",
        }),
        style: dptStyle,
      });

      /* layer communes */
      const normalStyle = new Style({
        stroke: new Stroke({
          color: "rgba(255,255,255,.4)",
          width: 1,
        }),
        fill: new Fill({
          color: "rgba(20,20,20,0)",
        }),
      });

      const hoverStyle = new Style({
        stroke: new Stroke({
          color: "#f00",
          width: 3,
        }),
        fill: new Fill({
          color: "rgba(200,20,20,0)",
        }),
      });

      var communesLayer = new VectorTileLayer({
        declutter: true,
        minZoom: 9,
        maxZoom: 11,
        source: new VectorTileSource({
          format: new MVT({
            idProperty: "INSEE_COM",
          }),
          url: "https://communes.vtiles.previmeteo.net/{z}/{x}/{y}.pbf",
        }),
        style: normalStyle,
      });

      /* radar layer */
      this.layers = new ImageLayer({
        source: new ImageStatic({
          projection: this.proj3106001,
          url: api.radar.getDay(this.selectedTimestamp),
          imageExtent: [-619652.074, -5062818.338, 916347.926, -3526818.338],
          imageSmoothing: false,
        }),
        opacity: 0.7,
      });

      /**
       * Popup layer
       */
      var container = document.getElementById("popup");
      var content = document.getElementById("popup-content");
      var closer = document.getElementById("popup-closer");
      var overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      closer.onclick = function () {
        overlay.setPosition(null);
        closer.blur();
        return false;
      };

      this.map = new Map({
        layers: [
          new LayerGroup({
            title: "Base maps",
            layers: [tileLayer, communesLayer, dptLayer, this.layers],
          }),
        ],
        overlays: [overlay],
        target: this.$refs.map,
        view: new View({
          center: fromLonLat([1, 47]),
          maxZoom: 10,
          zoom: 6,
        }),
      });

      var geocoder = new Geocoder("nominatim", {
        provider: "osm",
        lang: "fr-FR", //en-US, fr-FR
        placeholder: "Recherche pour ...",
        targetType: "text-input",
        limit: 5,
        keepOpen: true,
      });
      this.map.addControl(geocoder);

      var selection = {};
      var selectionLayer = new VectorTileLayer({
        map: this.map,
        renderMode: "vector",
        source: communesLayer.getSource(),
        style: function (feature) {
          if (feature.getId() in selection) {
            return hoverStyle;
          }
        },
      });

      this.map.on(
        ["click"],
        function (event) {
          const zoom = this.map.getView().getZoom();
          let layer = zoom < 9 ? dptLayer : communesLayer;

          var features = this.map.getFeaturesAtPixel(event.pixel);

          layer.getFeatures(event.pixel).then(function (features) {
            if (!features.length) {
              selection = {};
              selectionLayer.changed();
              return;
            }
            var feature = features[0];
            if (!feature) {
              return;
            }
            var fid = feature.getId();

            selection = {};

            // add selected feature to lookup
            selection[fid] = feature;

            selectionLayer.changed();

            if (feature.properties_) {
              content.innerHTML =
                feature.properties_.NOM_COM_M + " (" + fid + ")";
            } else {
              content.innerHTML = feature.values_.nom;
            }

            overlay.setPosition(event.coordinate);
          });
        }.bind(this)
      );
    },
    /**
     * setLayer()
     * timestamp : array , timestamp
     * param : day, hour
     */
    async setLayer(quarterTimestamp = false) {
      let timestamp = this.timelineObs[this.currentSelect].timestamp;
      let interval = this.timelineObs[this.currentSelect].interval;
      let param = "day";
      if (moment(timestamp).diff(moment(), "day") != 0) {
        param = "day";
      } else {
        param = "hour";
      }
      if (this.isHistory) {
        timestamp = moment(this.inputDate)
          .add(interval, "days")
          .format("YYYY-MM-DD");
      }
      if(!quarterTimestamp){
        this.selectedTimestamp = timestamp;
      }else{
        this.selectedTimestamp = quarterTimestamp * 1000;
      }
      let url = null,
        urlfcast = null;
      urlfcast = api.forecast.getDay(
        this.selectedTimestamp,
        moment(this.inputDate).format("YYYY-MM-DD")
      );
  let bboxDate = this.selectedTimestamp;
  if (param == "day") {
    url = api.radar.getDay(this.selectedTimestamp);
    bboxDate = moment(this.selectedTimestamp).format('YYYY-MM-DD')
      } else if (!this.isHistory) {
        this.selectedTimestamp = moment(this.selectedTimestamp).unix();
        url = api.radar.getQuarter(this.selectedTimestamp);
      } else {
        url = api.radar.getDay(this.selectedTimestamp);
      }
      this.map.removeLayer(this.layers);
      this.map.removeLayer(this.layersFcast);
      this.layers = new ImageLayer({
        source: new ImageStatic({
          projection: this.proj3106001,
          url: url,
          imageExtent: [-619652.074, -5062818.338, 916347.926, -3526818.338],
          imageSmoothing: false,
        }),
        opacity: 0.7,
      });
      try {
        const fcast_bbox = await api.forecast.getBbox(
          bboxDate,
          moment(this.inputDate).format("YYYY-MM-DD")
        );
        this.layersFcast = new ImageLayer({
          source: new ImageStatic({
            projection: "EPSG:4326",
            url: urlfcast,
            imageExtent: [
              fcast_bbox.lon_min,
              fcast_bbox.lat_min,
              fcast_bbox.lon_max,
              fcast_bbox.lat_max,
            ],
            imageSmoothing: false,
          }),
          opacity: this.layerFcastOpacity,
        });
        this.map.addLayer(this.layersFcast);

        try {
          await axios.get(url);
          this.stateObs = true;
        } catch (err) {
          this.stateObs = false;
        }
        try {
          await axios.get(urlfcast);
          this.stateFcast = true;
        } catch (err) {
          this.stateFcast = false;
        }
      } catch (err) {}

      this.map.addLayer(this.layers);
    },
    /**
     * setOpacity
     * Change l'opacité du layer
     */
    setOpacity(layer, value) {
      layer.set("opacity", value);
    },

    /**
						resetFocus();
						UI Function
				 */
    resetFocus() {
      if (this.timelineObs) {
        this.timelineObs.forEach((element) => {
          element.focus = false;
        });
      }
    },
    /**
     * initHours
     */
    initHours() {
      var myDate = moment().format("YYYY-MM-DD");
      let quarter = ["6", "12", "18"];
      for (var i = 0; i < quarter.length; i++) {
        let item = {
          label: moment(myDate).add(quarter[i], "hours").format("HH"),
          value: moment(myDate).add(quarter[i], "hours").unix(),
        };

        if (moment().unix() < moment(myDate).add(quarter[i], "hours").unix()) {
          item.disable = true;
        }
        this.hours.push(item);
      }
    },
    signout() {
      this.$store.dispatch("user/signout");
    },
    /**
     * resetMarkers()
     */
    resetMarkers() {
      for (var i = 0; i < this.markersList.length; i++) {
        this.markersList[i].remove();
      }
    },
  },
  mounted() {
    this.initMap();
    this.initHours();
    this.initLabels();
    this.setLayer();
  },
  watch: {
    currentSelect() {
      this.setLayer();
    },
    inputDate() {
      this.currentSelect = 3;
    },
    layerOpacity() {
      this.setOpacity(this.layers, this.layerOpacity);
    },
    layerFcastOpacity() {
      this.setOpacity(this.layersFcast, this.layerFcastOpacity);
    },
  },
};
</script>

<style scoped>
@import url("../styles/vue-bootstrap-css/bootstraper.scss");

.timelines {
  width: 90%;
  margin: auto;
  left: 0px;
  right: 0px;
  position: absolute;
  bottom: 2em;
  z-index: 1000;
}

.timeline {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1em;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
}

.status-bar {
  position: absolute;
  top: 5em;
  right: 0;
  z-index: 1000;
}

.time-container {
  display: block;
  background-color: rgba(119, 119, 119, 0.75);
  padding: 1em;
  border-radius: 10px;
}

.time span {
  cursor: pointer;
}

.time span:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  border-radius: 50%;
  padding: 0.8em;
}

.time span.focus {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  border-radius: 50%;
  padding: 0.8em;
}

.obs .time {
  display: inline-block;
  margin: auto;
  left: 0px;
  width: calc(100% / 7);
  right: 0px;
  text-align: center;
}

.cumul .time {
  display: inline-block;
  margin: auto;
  left: 0px;
  width: calc(100% / 11);
  right: 0px;
  text-align: center;
}

.time-bar {
  background-color: #ffffff;
  width: 100%;
  height: 10px;
  margin-top: 1em;
  border-radius: 10px;
}

.cumul .time-bar-use {
  background-color: #fdaf4e;
  height: 10px;
  float: right;
  position: absolute;
  right: 2em;
  overflow: hidden;
  z-index: 1000;
  border-radius: 10px;
  width: 32%;
}

.cumul .time-bar-use-cummu {
  background-color: rgb(20, 101, 148);
  position: absolute;
  right: 2em;
  z-index: 10;
  height: 10px;
  float: right;
  border-radius: 10px;
}

.obs .time-bar-use {
  background-color: #fdaf4e;
  height: 10px;
  z-index: 0;
  float: right;
  border-radius: 10px;
}

.options {
  position: absolute;
  bottom: 9em;
  left: 0px;
  width: 25%;
}

.panel {
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.header-panel {
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(0, 0, 0, 1);
}

.content-panel {
  padding: 1em;
}

.option-panel {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
}

input[type="date"] {
  max-width: 100%;
  min-width: 100%;
}

select.form-control {
  max-width: 100%;
  padding: 7px;
  min-width: 100%;
  border-radius: 3px;
}

input.mapboxgl-ctrl-geocoder--input {
  width: 100%;
}

svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none !important;
}

ul.suggestions {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000000;
}

ul.suggestions li {
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 5px;
}

ul.suggestions li:hover {
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 100%;
}

.mapboxgl-ctrl-top-right {
  width: 50%;
}

.loader {
  position: absolute;
  z-index: 2000;
  width: 100%;
  padding-top: 30%;
  text-align: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}

.disable {
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.3;
}

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 5px;
}

.legend li {
  width: auto;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  padding: 2px;
  border-radius: 5px;
  margin: 5px;
}

.alert-safari {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1em;
  text-align: center;
}

/* your colors */
.legend .classes1 {
  background-color: #fff600;
}

.legend .classes2 {
  background-color: #ffa500;
}

.legend .classes3 {
  background-color: #cf2727;
}

.legend .classes4 {
  background-color: #ff0202;
}

.legend .classes5 {
  background-color: #67389a;
}

.legend .classes6 {
  background-color: #ffffff;
}

@media (max-width: 1080px) {
  .options {
    width: 50%;
  }
}

.map {
  width: 100%;
  background: #f8f4f0;
}

html,
body,
.map {
  height: 100%;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 60px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
  color: #000;
}

div#popup-content {
  color: #000 !important;
}
</style>

<style>
a.leaflet-control-zoom-in {
  color: black !important;
}
</style>
