import api from '../api';
import router from '../router';
import { persist, restore } from '../utils/localstorage';

const state = {
	auth: null,
	user: null
};

const getters = {
	/** @returns {boolean} True if user is logged. */
	isLogged: state => state.auth != null
};

const mutations = {
	SET_AUTH(state, auth) {
		state.auth = auth;
		// mutate the localStorage too
		persist('auth', state.auth);
		// mutate axios auth
		api.setBearerToken(auth ? auth.token : null);
		// ensure user is logged out if token is null
		if (auth == null) {
			state.user = null;
		}
	},

	SET_USER(state, { user }) {
		state.user = user;
		// TODO mutate moment default timezone
		//if (user.timezone) {
		// moment.tz.setDefault(user.timezone);
		//}
	},
};

const actions = {
	async init({ commit, dispatch }) {
		var tokenGet = window.location.search.split('?token=');
		var auth = false;
		if (tokenGet[1]) {
			auth = {
				id_user: 1,
				token: tokenGet[1]
			}
			api.setBearerToken(tokenGet[1]);
			const fresh = await api.token.validate(auth.token);
			if (fresh != null) {
				commit('SET_AUTH', auth);
				await dispatch('loadUser');
			}
		} else {
			auth = restore('auth');
			if (auth) {
				const fresh = await api.token.validate(auth.token);
				if (fresh != null) {
					commit('SET_AUTH', auth);
					await dispatch('loadUser');
				}
			}
		}
	},

	async loadUser({ state, commit }) {
		const user = await api.users.get(state.auth.id_user);
		commit('SET_USER', { user });
	},

	async signin({ commit, dispatch }, { login, password }) {
		const auth = await api.token.get({ login, password });
		commit('SET_AUTH', auth);
		await dispatch('loadUser');
		router.push('/');
	},

	signout({ commit }) {
		commit('SET_AUTH', null);
		router.push('/login');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
