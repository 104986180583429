import Vue from 'vue';
import Vuex from 'vuex';

import VueSimpleAlert from "vue-simple-alert";


Vue.use(Vuex);

Vue.use(VueSimpleAlert);

// import api from '../api';
// import { persist, restore } from '../utils/localstorage';

/* Modules */
import user from './user';

/** Our application state */
export const rootState = {
	ready: false,
};

const getters = {

};

const mutations  = {
	SET_READY(state, b = true) {
		state.ready = b;
	},
};

const actions = {
	async init({ commit, dispatch }) {
		try {
			await dispatch('user/init');
			commit('SET_READY', true);
		} catch(e) {
			console.log(e);
		}
	},
};

const store = new Vuex.Store({
	modules: {
		user
	},
	state: rootState,
	getters,
	mutations,
	actions
});

export default store;
