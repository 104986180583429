<template>
  <div class="error">
      Vous utilisez Safari, à ce jour il existe un problème de compatibilité avec votre version de Safari indépendant de notre volonté.
      <br>
      Plus d'informations ici: <br>
      <a href="https://community.canvaslms.com/docs/DOC-10720-what-are-the-browser-and-computer-requirements-for-canvas">What are the browser and computer requirements for Canvas?</a>
  </div>
</template>

<script>
export default {

}
</script>

<style>


</style>