/** Persist some data to the local storage. */
export const persist = (key, data) =>
  data
    ? window.localStorage.setItem(key, JSON.stringify(data))
    : window.localStorage.removeItem(key);

/** Retrieve some persisted data from the local storage. */
export const restore = key =>
  window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key) || null)
    : null;
