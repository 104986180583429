import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'moment/locale/fr';
import moment from 'moment-timezone'
import api from './api';
import vuetify from './plugins/vuetify';

Vue.prototype.$moment = moment;
Vue.config.productionTip = false

// when auth token expires => reset the user
api.onTokenExpired(() => {
  store.commit('user/SET_TOKEN', null);
  router.push('/login');
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
