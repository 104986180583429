<template>
  <v-col md="6" sm="12" class="mx-auto my-16">
    <v-card>
      <v-card-title>
        <h3>Hailcast</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="email"
              label="Email ou nom d'utilisateur"
              v-model="login"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="password"
              label="Mot de passe"
              v-model="password"
            />
          </v-col>
          <v-col>
            <v-btn @click="submit"> Se connecter </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="errors" top>
       Login / Mot de passe incorrect
    </v-snackbar>
  </v-col>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      loading: false,
      login: "",
      password: "",
      errors: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch("user/signin", {
          login: this.login,
          password: this.password,
        });
      } catch (err) {
        this.loading = false;
        this.errors = err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  max-width: 20rem;
  margin: 10vh auto;

  input {
    width: 100%;
    display: block;
  }
  .pam {
    padding: 2rem;
  }
}
</style>
